import React from "react";
import "../css/main.css";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const navToContact = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };
  const navToRefund = () => {
    navigate("/refund");
    window.scrollTo(0, 0);
  };
  const navToPrivacy = () => {
    navigate("/privacy");
    window.scrollTo(0, 0);
  };

  return (
    <div className="main-container">
      <div className="finalDiv">
        <section className="footer__cols">
          <div className="footer_text">
            <p>ABOUT THE SHOP</p>
            <h5>
              A modern-day gentlemen’s outfitter.
              <br />
              Weaving the art of living into the fabric of life. Experience the
              &nbsp;
              <a href="https://www.instagram.com/explore/tags/benjaminbarker/">
                <span>#BenjaminBarker</span>
              </a>
              &nbsp;&nbsp;lifestyle today.
            </h5>
          </div>
          <div className="footer_list">
            <p>About Us</p>
            <ul>
              <li onClick={navToContact}>Contact Us</li>
              <li onClick={navToRefund}>Refund Policy</li>
              <li onClick={navToPrivacy}>Privacy Policy</li>
            </ul>
          </div>
          <div className="newsLetter">
            <p>NEWSLETTER</p>
            <h5>
              Subscribe to our mailing list below and we'll keep you in the loop
              about our latest sales and promotions.
            </h5>
            <div>
              <input type="email" name="email" placeholder="email address" />
            </div>
          </div>
        </section>
        <hr className="footer__divider" />
        <div className="footer-bottom">
          <div className="footer-currency-select">
            <select name="currency" id="currencySelect">
              <option value="china">China (SGD $)</option>
              <option value="afghanistan">Afghanistan (SGD S)</option>
              <option value="alandislands">Aland Islands(SGD S)</option>
              <option value="albania">Albania (SGD S)</option>
              <option value="algeria">Algeria (SGD S)</option>
              <option value="andorra">Andorra (SGD S)</option>
              <option value="angola">Angola (SGD S)</option>
            </select>
          </div>
          <div className="footer-payment">
            <img src="/images/image26.png" alt="AM" />
          </div>
        </div>

        <div className="footer-copyright">
          <p>@ All Rights Reserved 2024 - Powered by Shopify</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
