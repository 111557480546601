import React from "react";
import "../css/shop.css";
import "../css/contact.css";
import Header from "./Header";
import Footer from "./Footer";

function ContactUs() {

  return (
    <div className="main-container1">
      <Header />

      <div className="contcatDiv">
        <div className="h2cc">
          <h2>CONTACT US</h2>
        </div>

        <div className="contactMid">
          <div className="contact-content">
            <strong>OFFICE</strong>
            <p>+65 6281 1521</p>
            <p>MONDAY TO FRIDAY,1000AM TO 6.00PM</p>
          </div>
          <div className="contact-content">
            <strong>BUSINESS ENQUIRIES</strong>
            <p>CONTACTUS@BENJAMINBARKEY.CO</p>
          </div>
          <div className="contact-content">
            <strong>CAREERS</strong>
            <p>HIRING@BENJAMINBARKEY.CO</p>
          </div>
          <div className="contact-content">
            <strong>FRANCHISE ENQUIRIES</strong>
            <p>FRANCHISE@BENJAMINBARKEY.CO</p>
          </div>
          <div className="contact-content">
            <strong>MARKETING AND ENQUIRIES</strong>
            <p>MARKETING@BENJAMINBARKEY.CO</p>
          </div>
          <div className="contact-content">
            <strong>ONLINE ORDERS</strong>
            <p>ECOMMERCE@BENJAMINBARKEY.CO</p>
          </div>
          <div className="contact-content">
            <strong>TAILORING</strong>
            <p>CUSTOM@BENJAMINBARKEY.CO</p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
