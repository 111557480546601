import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantity, setQuantity] = useState(1);
  const [size, setSize] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { addToCart } = useContext(CartContext);

  const handleQuantityChange = (value) => {
    setQuantity(value);
  };

  const selectSize = (size) => {
    setSize(size);
    setSelectedSize(size);
  };

  const handleAddToCart = () => {
    if (!size) {
      alert("Please select a size before adding to cart.");
      return;
    }

    addToCart(product.id, size, quantity);
    navigate("/cartpage");
  };

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div className="product-detail-container">
      <Header />
      <div className="product-detail">
        <div className="product-images">
          <div className="carousel">
            {product.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${product.name}`}
                style={{ display: index === currentIndex ? "block" : "none" }}
              />
            ))}
          </div>
          <div className="carousel-buttons">
            {product.images.map((image, index) => (
              <button key={index} onClick={() => setCurrentIndex(index)}>
                <img src={image} alt={`${product.name}`} />
              </button>
            ))}
          </div>
          <div className="goBackToShop">
            <a href="/">{"<"}&nbsp;&nbsp;&nbsp;Go Back To Shopping.</a>
          </div>
        </div>
        <div className="product-info">
          <p className="product-brand">
            <a href="https://www.benjaminbarker.co/collections/vendors?q=Benjamin%20Barker">
              {product.brand}
            </a>
          </p>
          <h1>{product.mains}</h1>
          <div className="product-sizes">
            <h3>Size: {size}</h3>
            <ul>
              {product.size.map((size, index) => (
                <li
                  key={index}
                  onClick={() => selectSize(size)}
                  className={selectedSize === size ? "selected" : ""}
                >
                  {size}
                </li>
              ))}
            </ul>
          </div>
          <div className="priceDiv">
            <p className="product-prePrice">{product.preprice}</p>
            <p className="product-price">{product.price}</p>
          </div>
          <p className="product-description">{product.description}</p>
          <div className="product-quantity">
            <h3>Quantity</h3>
            <div className="takeQuantity">
              <button
                className="quantity-button"
                onClick={() => handleQuantityChange(quantity - 1)}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <input
                type="number"
                value={quantity}
                className="quantity-input"
                onChange={(e) =>
                  handleQuantityChange(parseInt(e.target.value, 10))
                }
              />
              <button
                className="quantity-button"
                onClick={() => handleQuantityChange(quantity + 1)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
          <div className="btnDiv">
            <button className="add-to-cart" onClick={handleAddToCart}>
              Add to Cart
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ProductDetail;
