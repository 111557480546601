import React, { useState } from "react";
import "../css/read.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

function ReadMore() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="main-container1">
      <div className="headerDiv1">
        <div className="center1">
          <a href="/" className="headerLink1">
            <img
              src="/images/shopheader.png"
              alt="Logo"
              className="header__logo1"
            />
          </a>
        </div>
        <div className="right1" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBriefcase} />
        </div>
      </div>

      {sidebarOpen && (
        <div className="sidebar">
          <div className="sidebar-header">
            <h3>BENJAMIN BARKER</h3>
            <span onClick={toggleSidebar}>X</span>
          </div>
          <div className="sidebar-content">
            <ul>
              <li>Cart</li>
              <li className="viewed">Recently Viewed</li>
            </ul>
            <div className="cart-status">
              <p>Your cart is currently empty.</p>
            </div>
          </div>
        </div>
      )}

      <div className="midDiv03">
        <p>JULY 3,2024</p>
        <h2 className="midDiv03h2" style={{ fontWeight: 600 }}>
          Japanese Fabric - A Symphony of Fine Innovation
        </h2>
        <p>Benjamin Barker</p>
        <div className="read">
          <a href="/shop" className="readA">
            Share
          </a>
        </div>
        <br />
        <div>
          <img src="/images/img22.jpg" alt="pic" />
          <br />
          <br />
          <p>
            Indulge yourself in unparalleled luxury where tradition meets
            innovation.
          </p>
          <br />
          <p>
            Welcome to the world of
            <strong>Japanese fabrics</strong>
          </p>
          <br />
          <p>
            Revered for their astonishing beauty, intricate designs, and
            sustainable production methods, these exquisite textiles are crafted
            in true Japanese fashion; with heart and soul.
          </p>
          <br />
          <p>
            Embodying nature’s gentleness, each thread exudes a graceful
            elegance, perfect for the discerning gentleman portraying simple
            sophistication in their suits.
          </p>
          <br />
          <h2>Why Japanese Fabric?</h2>
          <br />
          <br />
          <p>
            The journey of Japanese fabric starts from the precise spinning of
            fibres to the meticulous weaving process, reflecting an unwavering
            dedication to perfection. With a harmonious blend of tradition and
            modernity, the result is a fabric that not only captivates with its
            beauty but also impresses with its durability and functionality,
            making it an essential addition to any sartorial wardrobe.
          </p>
          <br />
          <br />
          <h3>Superior Raw Materials</h3>
          <br />
          <br />
          <p>
            At the heart of its excellence lies the selection of superior raw
            materials.
          </p>
          <br />
          <p>
            Only the finest cotton, silk, and linen are sourced from the world’s
            premier producers. These materials are woven into fabrics that are
            as soft as a whisper, yet strong enough to withstand the test of
            time.
          </p>
          <br />
          <p>
            This commitment to quality ensures that each piece maintains its
            pristine appearance and luxurious feel, making Japanese fabric the
            ideal choice for a versatile summer suit.
          </p>
          <br />
          <br />
          <h3>Luxury and Versatility of Japanese Fabrics</h3>
          <br />
          <p>
            Japanese fabrics offer unmatched versatility; effortlessly
            transitioning from day to night, from casual to formal.
          </p>
          <br />
          <p>
            Whether it’s a summer wedding or a business luncheon, these
            cool-touch and wrinkle-resistant suits provide the perfect balance
            of comfort and style. Their lightweight nature ensures
            breathability, while their sophisticated designs make a bold
            statement. Embrace the essence of luxury with a fabric that adapts
            to your lifestyle and elevates your wardrobe.
          </p>
          <br />
          <div className="readMidImg">
            <div>
              <img src="/images/img22.jpg" alt="pic" />
              <p>
                Benjamin Barker Nouchi Cinder Suit Jacket made with Japanese
                fabrics
              </p>
            </div>
            <div>
              <img src="/images/img22.jpg" alt="pic" />
              <p>
                Benjamin Barker Hajime Rust Linen Suit Jacket made with Japanese
                fabrics
              </p>
            </div>
          </div>
          <br />
          <br />
          <h3>The Finishing Touches</h3>
          <br />
          <br />
          <p>
            It’s all about finding that perfect balance between style and
            comfort, even during the warmer months. Elevate your Japanese
            fabrics by mastering the art of layering. Add a touch of personality
            with a supple leather belt or a refined fabric bandana to seamlessly
            blend your entire look together.
          </p>
          <br />
          <br />
          <p className="readFinalP">
            Shop Benjamin Barker’s Suits Online or In-stores
          </p>
          <br />
          <br />
          <hr />
          <h3>Read More</h3>
        </div>
      </div>

      <div className="finalDiv">
        <section className="footer__cols">
          <div className="footer_text">
            <p>ABOUT THE SHOP</p>
            <h5>
              A modern-day gentlemen’s outfitter.
              <br />
              Weaving the art of living into the fabric of life. Experience the
              &nbsp;
              <a href="https://www.instagram.com/explore/tags/benjaminbarker/">
                <span>#BenjaminBarker</span>
              </a>
              &nbsp;&nbsp;lifestyle today.
            </h5>
          </div>
          <div className="footer_list">
            <p>About Us</p>
            <ul>
              <li>Contact Us</li>
              <li>Refund Policy</li>
              <li>Privacy Policy</li>
            </ul>
          </div>
          <div className="newsLetter">
            <p>NEWSLETTER</p>
            <h5>
              Subscribe to our mailing list below and we'll keep you in the loop
              about our latest sales and promotions.
            </h5>
            <div>
              <input type="email" name="email" placeholder="email address" />
            </div>
          </div>
        </section>
        <hr className="footer__divider" />
        <div className="footer-bottom">
          <div className="footer-currency-select">
            <select name="currency" id="currencySelect">
              <option value="china">China (SGD $)</option>
              <option value="afghanistan">Afghanistan (SGD S)</option>
              <option value="alandislands">Aland Islands(SGD S)</option>
              <option value="albania">Albania (SGD S)</option>
              <option value="algeria">Algeria (SGD S)</option>
              <option value="andorra">Andorra (SGD S)</option>
              <option value="angola">Angola (SGD S)</option>
            </select>
          </div>
          <div className="footer-payment">
            <img src="/images/image26.png" alt="AM" />
          </div>
        </div>

        <div className="footer-copyright">
          <p>@ All Rights Reserved 2024 - Powered by Shopify</p>
        </div>
      </div>
    </div>
  );
}

export default ReadMore;
