import React, { useState, useContext } from "react";
import "../css/main.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../Component/CartContext";
import { useNavigate } from "react-router-dom";

function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const { cartItems } = useContext(CartContext);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    navigate("/cartpage");
  };

  return (
    <div className="main-container">
      <div className="headerDiv">
        <div className="headerDiv1">
          <div className="center1">
            <a href="/" className="headerLink1">
              <img
                src="/images/shopheader.png"
                alt="Logo"
                className="header__logo1"
              />
            </a>
          </div>
          <div className="right1" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faCartShopping} size="2xl" />
            <span className="cart-count">{cartItems.length}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
