import React from "react";
import "../css/shop.css";
import "../css/contact.css";
import Header from "./Header";
import Footer from "./Footer";

function Refund() {
  return (
    <div className="main-container1">
      <Header />
      <div className="contcatDiv">
        <div className="h2cc">
          <h2>Refund policy</h2>
        </div>

        <div className="refundMid">
          <p>
            <strong>Exchanges & Refunds (Singapore) </strong>
            <br />
            <span>
              If you are unsatisfied with your order, you may request for an
              exchange.
            </span>
            <br />
            <br />
            <span>
              Please note that refunds and exchanges do not apply to the
              following items:
            </span>
            <br />
            <strong>Benjamin Barker Off The Rack Collection </strong>
            <br />
            <strong>Online Exclusive Sale Collection</strong>
            <br />
            <strong> Shoes and accessories</strong>
            <br />
            <br />
            <span>
              To request for an exchange or refund, please follow the steps
              below:
            </span>
            <br />
            <br />
            <span>
              - Send an email to orders@benjaminbarker.co within 30 days,
              stating your order number, reason for exchange/refund and item to
              exchange to.
            </span>
            <br />
            <br />
            <span>
              - You will then receive a follow-up email stating all relevant
            </span>
            <br />
            <span>
              information, including a specified store location to make your
              exchange.
            </span>
            <br />
            <br />
            <span>
              Kindly bring along your item to stated store for an exchange.
            </span>
            <br />
            <br />
            <span>
              Refunds will only be given for defective products that cannot be
              replaced with the same or similar item. Where applicable, store
              credits equivalent to value of the returned item will be issued.
            </span>
            <br />
            <br />
            <span>
              Benjamin Barker reserves the right to refuse any exchanges if
              returned goods are in unsatisfactory conditions.
            </span>
            <br />
            <br />
            <span>
              For more information, please refer to Terms & Conditions below.
            </span>
          </p>
          <p>
            <a>
              Please refer to Terms & Conditions below for more information.
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Refund;
