import React from "react";
import "../css/paysuccess.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Footer from "./Footer";

const PaySuccess = () => {
  return (
    <>
      <Header />
      <div className="mainPay">
        <div className="success-container">
          <div className="x-mark">
            <FontAwesomeIcon icon={faSquareCheck} />{" "}
          </div>
          <div className="message">
            <p>Success</p>
            <button>OK</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaySuccess;
