import React, { useState } from "react";
import "../css/main.css";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import productData from "../Datas/productData";

function Main() {
  const navigate = useNavigate();
  const [shopOpen, setShopOpen] = useState(false);
  const [hoverImg, setHoverImg] = useState(null);

  const doShop = () => {
    setShopOpen(!shopOpen);
  };

  const toProductDetails = (id) => {
    navigate(`/product/${id}`);
    window.scrollTo(0, 0);
  };

  const enterMouse = (hoverImg) => {
    setHoverImg(hoverImg);
  };

  const leaveMouse = () => {
    setHoverImg(null);
  };
  return (
    <div className="main-container">
      <Header />
      <div
        className="mainDiv"
        style={{ backgroundImage: "url('images/img01.jpg')" }}
        onClick={() => toProductDetails(4)}
      >
        <div className="firstDiv">
          <p>NEW ARRIVAL</p>
          <h1>Bringing The Cool To Touch</h1>
          <a href="/product/4" className="shopHref">
            SHOP NOW
          </a>
        </div>
      </div>
      <div className="midContent">
        {productData
          .filter((product) => product.id >= 1 && product.id <= 3)
          .map((product) => (
            <div
              className="aDiv"
              key={product.id}
              onClick={() => toProductDetails(product.id)}
            >
              <div className="midImg">
                <img src={product.images[0]} alt={product.name} />
                <div className="text-content">
                  <h2 className="midTopContext">{product.mains}</h2>
                  <p>{product.product}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="midDiv03">
        <h2 className="midDiv03h2">Keeping It Casual</h2>
        <p>Shop our latest collection now.</p>
        <div className="midContent02">
          {productData
            .filter((product) => product.id >= 5 && product.id <= 7)
            .map((product) => (
              <div
                className="aDiv02"
                key={product.id}
                onClick={() => toProductDetails(product.id)}
              >
                <div
                  className="midImg"
                  onMouseEnter={() => enterMouse(product.id)}
                  onMouseLeave={leaveMouse}
                >
                  <img
                    src={
                      hoverImg === product.id
                        ? product.images[1]
                        : product.images[0]
                    }
                    alt={product.name}
                  />
                  <div className="main-sale-badge">ON SALE</div>
                  <div className="quick-shop2" onClick={doShop}>
                    <button>SHOP NOW</button>
                  </div>
                  <div className="text-content">
                    <h2 className="midTopContext">{product.mains}</h2>
                    <p>{product.price}</p>
                    <p className="svgP">
                      Get Cashback when you pay with
                      <img src="images/img31.svg" alt="svg" />
                      <p>SHOP NOW</p>
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="midContent03">
        {productData
          .filter((product) => product.id >= 8 && product.id <= 9)
          .map((product) => (
            <div
              className="aDiv03"
              key={product.id}
              onClick={() => toProductDetails(product.id)}
            >
              <div className="midImg">
                <img src={product.images[0]} alt={product.name} />
                <div className="img21Context">
                  <h4>{product.first}</h4>
                  <h2>{product.mains}</h2>
                  <div>
                    <p>{product.description}</p>
                  </div>
                  <a href={`/product/${product.id}`} className="shopHref3">
                    SHOP NOW
                  </a>
                </div>
              </div>
            </div>
          ))}
      </div>

      <div className="botDiv">
        <h2 className="midDiv03h2">STAY CONNECTED</h2>
        <p>Say Hello!</p>
        <h5>Stay connected with our latest post and daily updates.</h5>

        <div className="midContent04">
          {productData
            .filter((product) => product.id >= 10 && product.id <= 12)
            .map((product) => (
              <div
                className="aDiv04"
                onClick={() => toProductDetails(product.id)}
              >
                <div className="midImg">
                  <img src={product.images[0]} alt={product.name} />
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Main;
