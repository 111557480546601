import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CheckOut = () => {
  const { cartItems } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [selected, setSelected] = useState("US");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!country) newErrors.country = "Country is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!city) newErrors.city = "City is required";
    if (!postalCode) newErrors.postalCode = "Postal code is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      alert("Order placed!");
      navigate("/paysuccess");
    }
  };

  const isFormValid = () => {
    return (
      email && country && phone && firstName && lastName && city && postalCode
    );
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      return total + parseFloat(product.price.replace("$", "")) * item.quantity;
    }, 0);
  };

  return (
    <>
      <Header />
      <div className="check-custom-checkout-page">
        <div className="left">
          <div className="check-order-info">
            <h2>Billing Information</h2>
            <div>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email (for sending your order receipt):"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <ReactFlagsSelect
              selected={selected}
              onSelect={(code) => {
                setSelected(code);
                setCountry(code);
              }}
              className="check-select"
            />

            {errors.selected && <p className="error">{errors.selected}</p>}
            <div>
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First name (optional):"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {errors.firstName && <p className="error">{errors.firstName}</p>}
            </div>
            <div>
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last name:"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              {errors.lastName && <p className="error">{errors.lastName}</p>}
            </div>
            <div>
              <input
                type="text"
                id="city"
                name="city"
                placeholder="City:"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>
            <PhoneInput
              country={country.toLowerCase()}
              value={phone}
              onChange={(number) => setPhone(number)}
              className="check-input"
              style={{ width: "100%" }}
            />

            {errors.phone && <p className="error">{errors.phone}</p>}
            <div>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                placeholder="Postal code:"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
              {errors.postalCode && (
                <p className="error">{errors.postalCode}</p>
              )}
            </div>
            <button
              className="check-checkout-button"
              onClick={handlePlaceOrder}
              disabled={!isFormValid()}
            >
              Pay Now
            </button>
          </div>
        </div>
        <div className="right">
          <div className="check-order-summary">
            <h2>Order Summary</h2>
            <div className="check-order-details">
              {cartItems.map((item, index) => {
                const product = getProductById(item.id);
                return (
                  <div key={index} className="check-cart-item">
                    <img src={product.images[0]} alt="Product" />
                    <div className="check-item-details">
                      <h3>{product.mains}</h3>
                      <p>Price: {product.price}</p>
                      <p>Size: {item.size}</p>
                      <p>Quantity: {item.quantity}</p>
                    </div>
                  </div>
                );
              })}

              <div className="check-summary-details">
                <div className="gridTotal">
                  <span>Quantity Total:</span>
                  <span className="endMsg">{cartItems.length}</span>
                </div>
                <div className="gridTotal">
                  <span className="grand">Grand Total:</span>
                  <span className="grand1">${calculateTotal().toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
