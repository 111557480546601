import React, { useContext } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const { cartItems, removeFromCart } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      return total + parseFloat(product.price.replace("$", "")) * item.quantity;
    }, 0);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    navigate("/checkout");
  };

  return (
    <>
      <div className="cart-page">
        <Header />
        <div className="cart-header">
          <h1 style={{ color: "black" }}>Your Shopping Cart</h1>
        </div>
        <div className="cart-items">
          {cartItems.length === 0 ? (
            <h3 style={{ color: "black", textAlign: "center" }}>
              Your Cart is Empty!
            </h3>
          ) : (
            cartItems.map((item, index) => {
              const product = getProductById(item.id);
              return (
                <div key={index} className="cart-item">
                  <img src={product.images[0]} alt="Product" />
                  <div className="item-details">
                    <div className="item-info">
                      <h3>{product.mains}</h3>
                      <p>Price: {product.price}</p>
                      <p>Size: {item.size}</p>
                      <p>Quantity: {item.quantity}</p>
                    </div>
                    <div className="remove-button">
                      <button onClick={() => removeFromCart(item.id)}>
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {cartItems.length > 0 && (
          <div className="cart-summary">
            <div className="rightSum">
              <h2>Cart Summary</h2>
              <div className="summary-details">
                <p className="grayP">Total Items: {cartItems.length}</p>
                <p className="grayP">
                  Subtotal (Refresh page if discount is not triggered):
                </p>
                <span> ${calculateTotal().toFixed(2)}</span>
                <p>Tax included and shipping calculated at checkout.</p>
              </div>
              <button className="checkout-button" onClick={toCheckOut}>
                CHECK OUT
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CartPage;
