// src/productData.js

const productData = [
  {
    id: 1,
    name: "Performance Pima Box Tee",
    description:
      "Includes 2 Suit Jackets, 2 Suit Pants + 1 Shirt or Pants or Chinos or Polo of your choice for just $599.",
    price: "$55.30",
    preprice: "$99.90",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img03.jpg"],
    mains: "Suit",
    product: "13 products",
    brand: "BENJAMIN BARKER",
  },
  {
    id: 2,
    name: "Another Product",
    description: "This is another product description.",
    price: "$59.00",
    preprice: "$99.90",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img04.jpg"],
    mains: "Formal",
    product: "83 products",
    brand: "BENJAMIN BARKER",
  },
  {
    id: 3,
    name: "Another Product",
    description: "This is another product description.",
    price: "$69.00",
    preprice: "$99.90",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img05.jpg"],
    mains: "Casual",
    product: "20 products",
    brand: "BENJAMIN BARKER",
  },
  {
    id: 4,
    name: "Nouchi Cinder Suit Jacket",
    description:
      "Japanese fabrics; where tradition meets innovation. From superior raw materials to intricate designs, learn why these textiles are ideal for versatile summer suits.",
    price: "$225.00",
    preprice: "$450.00",
    size: ["46", "48", "50"],
    images: ["/images/shop01.jpg", "/images/shop02.jpg", "/images/shop03.jpg"],
    mains: "Japanese Fabric - A Symphony of Fine Innovation",
    product: "13 products",
    first: "STORIES",
  },
  {
    id: 5,
    name: "Performance Pima Box Tee",
    description:
      "Includes 2 Suit Jackets, 2 Suit Pants + 1 Shirt or Pants or Chinos or Polo of your choice for just $599.",
    price: "$79.92",
    preprice: "$99.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img27.webp", "/enterImg/img1.jpg"],
    mains: "Misura Embroidered White Linen",
    product: "13 products",
  },
  {
    id: 6,
    name: "Another Product",
    description: "This is another product description.",
    price: "$48.93",
    preprice: "$69.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/enterImg/img2.jpg", "/enterImg/img3.jpg"],
    mains: "BB Logo Polo Tee",
    product: "83 products",
  },
  {
    id: 7,
    name: "Another Product",
    description: "This is another product description.",
    price: "$89.90",
    preprice: "$109.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/enterImg/img4.jpg", "/enterImg/img5.jpg"],
    mains: "Wool Silk Tennis Polo",
    product: "20 products",
  },
  {
    id: 8,
    name: "Performance Pima Box Tee",
    description:
      "Japanese fabrics; where tradition meets innovation. From superior raw materials to intricate designs, learn why these textiles are ideal for versatile summer suits.",
    price: "$69.93",
    preprice: "$99.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img10.jpg"],
    mains: "Thoresby Blue Check",
    product: "13 products",
    first: "STORIES",
  },
  {
    id: 9,
    name: "Another Product",
    description:
      "The best things are those that achieve a delicate balance. This collection expertly navigates between styles and moods, offering flexibility for every occasion. It brings out the sweet spot where",
    price: "$69.93",
    preprice: "$69.00",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/enterImg/img6.jpg"],
    mains: "Langston Easycare Khaki",
    product: "83 products",
    first: "STORIES",
  },
  {
    id: 10,
    name: "Performance Pima Box Tee",
    description:
      "Includes 2 Suit Jackets, 2 Suit Pants + 1 Shirt or Pants or Chinos or Polo of your choice for just $599.",
    price: "$69.93",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img32.webp"],
    mains: "Stripe Crew Pocket Tee",
    product: "13 products",
  },
  {
    id: 11,
    name: "Another Product",
    description: "This is another product description.",
    price: "$79.92",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img32.jpg"],
    mains: "Takimi Embroidered Blue Linen",
    product: "83 products",
  },
  {
    id: 12,
    name: "Another Product",
    description: "This is another product description.",
    price: "$55.93",
    size: ["XS", "S", "M", "L", "XL"],
    images: ["/images/img37.webp"],
    mains: "Performance Pima Box Tee",
    product: "20 products",
  },
];

export default productData;
